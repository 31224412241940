import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSpring, to as interpolate, animated, config } from '@react-spring/web';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { SiteContext } from 'context/site-context';
import useMeasure from 'react-use-measure';
import { Section } from 'components/Section/Section';
import MenuItem from 'components/MenuItem/MenuItem';
import { Sandwich } from 'components/Icons/Icons';
import { media } from 'utils/mediaQuery';
import useMatchMedia from 'utils/matchMedia';

const Wrapper = styled.header`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  display: flex;
  flex-direction: column;
  pointer-events: none;

  ${media.small`
    display: block;
    top: 0;
    bottom: auto;
    width: 50%;
    padding-top: 10px;
  `}
`

const Cover = styled(animated.div)`
  position: relative;
  flex: 1;
  width: 100%;

  ${media.small`
    display: none;
  `}
`

const Panel = styled(animated.div)` 
 height: 212px;

 ${media.small`
    height: auto;
    max-height: initial;
  `}
`

const CustomSection = styled(Section)`
  background: rgba(var(--brand-background),0.6);
  backdrop-filter: blur(20px);
  pointer-events: auto;
  height: 100%;

  ${media.small`
    background: none;
    backdrop-filter: none;
  `}

`

const MobileNav = styled.div`
  padding-left: 10px;
  padding-right: 2px;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  position: relative;

  ${media.small`
    display: none;
  `}
`

const Bar = styled(animated.div)`
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  
  ${media.small`
    background: rgba(var(--brand-background),0.6);
    backdrop-filter: blur(20px);
    border-radius: 2px;
    height: 33px;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 1;
  `}
`

const NavItems = styled.ul`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
`

const CustomMenuItem = styled(MenuItem)`
  transition: opacity .2s ease;
  opacity: ${props => props.mobileFade || props.fade ? 0.2 : 1};
  ${media.small`
    opacity: ${props => props.fade ? 0.2 : 1};
  `}
`

const NavItem = styled.li`
  position: relative;
`

const MobileHomeLink = styled(Link)`
  display: inline-block;
`

const HomeDesktoItem = styled(NavItem)` 
  display: none;
  ${media.small`
    display: block;
  `}
`

const Header = ({location, triggeredTransition}) => {
  const [ Active, setActive ] = React.useState(null);
  const [ Current, setCurrent ] = React.useState(location.pathname.replaceAll('/', ''));
  const [ isMobileMenu, setIsMobileMenu ] = React.useState(false);
  //const [ ref, bounds ] = useMeasure(); 
  const { setInfoBounds } = React.useContext(SiteContext);
  const isDesktop = useMatchMedia('(min-width:768px)', true)

  const [ propsItems, apiItems ] = useSpring(() => ({ to: { opacity: isMobileMenu || isDesktop ? 1 : 0}, immediate: true }), [isDesktop])
  const [ propsPanel, apiPanel ] = useSpring(() => ({ to: { maxHeight: isMobileMenu && !isDesktop ? 212 : !isMobileMenu && !isDesktop ? 56 : "initial"}, immediate: true }), [isDesktop])
  const [ propsCover, apiCover ] = useSpring(() => ({ to: { background: isMobileMenu && !isDesktop ? 'rgba(17,17,17,0.8)' : 'rgba(17,17,17,0)'}, immediate: true }), [isDesktop])

  const data = useStaticQuery(graphql`
    query headerQuery {
      header {
        indexCount,
        projectsCount
      }
    }
  `)

  useEffect(() => {

    if(!isDesktop) {
      if(isMobileMenu) {
        apiItems.start({
          opacity: 1,
          delay: 400
        })

        apiPanel.start({
          maxHeight: 212,
        })

        apiCover.start({
          background: 'rgba(17,17,17,0.8)'
        })

      } else {
        apiItems.start({
          opacity: 0,
        })

        apiPanel.start({
          maxHeight: 56,
          delay: 400
        })

        apiCover.start({
          background: 'rgba(17,17,17,0)',
          delay: 400
        })
      }
    } else {
      apiItems.start({
        opacity: 1
      })

      apiPanel.start({
        maxHeight: "initial",
      })

      apiCover.start({
        background: 'rgba(17,17,17,0)',
      })
    }
   

  }, [isMobileMenu, isDesktop])

  const infoRef = React.useCallback(node => {
    if (node !== null) {
      let bounds;
      if(triggeredTransition === null || triggeredTransition) {
        bounds = node.getBoundingClientRect();
        setInfoBounds(bounds);
      }
    } 
  }, [triggeredTransition])

  React.useEffect(() => {
    setIsMobileMenu(false)
    setCurrent(location.pathname.replaceAll('/', ''))
  }, [location])

  const projects = data ? data.header.projectsCount : null;
  const index = data ? data.header.indexCount : null;

  const mobileClickHandler = (e,path) => {
    if(path === Current && !isDesktop) {
      e.preventDefault();
      setIsMobileMenu(false)
    }
  }
  return (
    <Wrapper>
      <Cover style={propsCover}/>
      <Panel style={propsPanel}>
        <CustomSection> 
          <MobileNav>
            <MobileHomeLink to="/" onClick={(e)=>mobileClickHandler(e,"")}>
              <CustomMenuItem>Abdela Igmirien</CustomMenuItem>
            </MobileHomeLink>
            <Sandwich onClick={()=>setIsMobileMenu(!isMobileMenu)} close={isMobileMenu}/>
          </MobileNav>
          <Bar style={propsItems}>
            <NavItems>
              <HomeDesktoItem>
                <Link to="/">
                  <CustomMenuItem fade={Active && Active !== "/"} mobileFade={Current === ""} onMouseEnter={() => setActive("/")} onMouseLeave={() => setActive(null)}>Abdela Igmirien</CustomMenuItem>
                </Link>
              </HomeDesktoItem>
              <NavItem>
                <Link to="/projects" onClick={(e)=>mobileClickHandler(e,"projects")}>
                  <CustomMenuItem fade={Active &&  Active !== "/projects"} mobileFade={Current === "projects"} onMouseEnter={() => setActive("/projects")} onMouseLeave={() => setActive(null)} num={projects}>Projects</CustomMenuItem>
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/reference" onClick={(e)=>mobileClickHandler(e,"reference")}>
                  <CustomMenuItem fade={Active && Active !== "/reference"} mobileFade={Current === "reference"} onMouseEnter={() => setActive("/reference")} onMouseLeave={() => setActive(null)} num={index}>Index</CustomMenuItem>
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/info" onClick={(e)=>mobileClickHandler(e,"info")}>
                  <CustomMenuItem fade={Active && Active !== "/info"} mobileFade={Current === "info"} onMouseEnter={() => setActive("/info")} onMouseLeave={() => setActive(null)} ref={infoRef}>Info</CustomMenuItem>
                </Link>
              </NavItem>
            </NavItems>
          </Bar>
        </CustomSection>
      </Panel>
    </Wrapper>
  )
}

export default Header;
