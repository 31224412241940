import { css } from "styled-components";

const stacks = {
  // ns = not small
  default: () =>
    css`
      font-family: "Basis Grotesque Pro",Arial,Helvetica Neue,Helvetica,sans-serif;
    `,
  secondary: () =>
    css`
      font-family: "Basis Grotesque Mono Pro", Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace;
    `,
};

export const fontstack = stacks;
