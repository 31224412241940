import { css } from 'styled-components';

export const type = (type) => {

  const types = {
    superscript: css`
      font-weight: normal;
      font-size: 8px;
      line-height: 10px;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    `,
    metadata: css`
      font-weight: normal;
      font-size: 10px;
      line-height: 14px;
      text-transform: uppercase;
      letter-spacing: 0.8px;
    `,
    headdt: css`
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
      letter-spacing: 1.04;
    `,
    headmb: css`
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      letter-spacing: 1.04;
    `,
    body: css`
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1.02;
    `,
  }

  return types[type];
}