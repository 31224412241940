import React from 'react';
import styled from 'styled-components';
import { media } from 'utils/mediaQuery'
import CloseIcon from './assets/close.svg';
import ArrowIcon from './assets/arrow.svg';
import SandwichIcon from './assets/sandwich.svg';

const Base = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: background .2s ease;
  cursor: pointer;
  
  &:active {
    background: rgba(var(--brand-white),1);
  }
  
  ${media.small`
    width: 40px;
    height: 40px;

    &:hover {
      background: rgba(var(--brand-white),1);
    }

    &:active {
    background: rgba(var(--brand-white),0);
  }
  `}

`

const CloseWrapper = styled(Base)`
  
`

const Close = ({...rest}) => {

  return (
    <CloseWrapper {...rest}>
      <CloseIcon />
    </CloseWrapper>
  )
}

export { Close }


const SandwichWrapper = styled(Base)`
  
  & > svg {
    overflow: visible;
  }

  & rect {
    transition: all .2s ease;
  }

  & rect:first-child {
    transform: ${props => props.close ? "rotate(45deg) translate(0px,3.3px)" : "rotate(0deg) translate(0px,0px)"} ;
    transform-origin: center;
  }

  & rect:last-child {
    transform: ${props => props.close ? "rotate(-45deg) translate(0px,-3.3px)" : "rotate(0deg) translate(0px,0px)"} ;
    transform-origin: center;
  }
`

const Sandwich = ({...rest}) => {

  return (
    <SandwichWrapper {...rest}>
      <SandwichIcon />
    </SandwichWrapper>
  )
}

export { Sandwich }

const ArrowWrapper = styled(Base)`
  backdrop-filter: blur(20px);
  background: rgba(var(--brand-white),0.5);

  &:hover {
    background: rgba(var(--brand-white),1);
  }

  &:active {
    background: rgba(var(--brand-white),0.5);
  }

  & > svg {
    transform: ${props => props.rotated ? 'rotate(180deg)' : 'rotate(0deg)'};
  }
`

const Arrow = ({rotated, ...rest}) => {

  return (
    <ArrowWrapper rotated={rotated} {...rest}>
      <ArrowIcon />
    </ArrowWrapper>
  )
}

export { Arrow }
