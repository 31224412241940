import React, { useLayoutEffect, useState } from 'react';
import debounce from "lodash.debounce"

/**
 * CSS example: height: calc(var(--vh, 1vh) * 100);
 */
function useViewportHeight() {
  const CachedWidth = React.useRef(null);
  const [ IsHeightSet, setIsHeightSet ] = useState(false);
  useLayoutEffect(() => {
    function updateSize() {
      debouncedUli([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const uli = debounce(
    (value) => {
      // only prevent vh update if width hasn't changed on mobile
      /*if(value[0] === CachedWidth.current && value[0] < 768) 
        return;
      */

      let vh = value[1] * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      CachedWidth.current = value[0];
      setIsHeightSet(true)
      
    },
    300
  )

  const debouncedUli = React.useCallback((value) => uli(value), [])

  return IsHeightSet;
}

export default useViewportHeight;