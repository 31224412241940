import React from 'react';
import styled from 'styled-components';

const SectionWrapper = styled.div`
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
`

const SectionGuttersWrapper = styled.div`
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
`


const Section = ({children, className}) => {

  return (
    <SectionWrapper className={className}>
      {children}
    </SectionWrapper>
  )
}

export { Section };

const SectionGutters = ({children, className}) => {

  return (
    <SectionGuttersWrapper className={className}>
      {children}
    </SectionGuttersWrapper>
  )
}

export { SectionGutters };
