import * as React from "react";
import sanityClient from "@sanity/client";
import useViewportHeight from "utils/useViewportHeight";

const defaultValues = {};

export const SiteContext = React.createContext(defaultValues);

export const SiteProvider = ({ children }) => {
  const [ InfoShiftValue, setInfoShiftValue ] = React.useState(null);
  const IsHeightSet = useViewportHeight();

  const client = sanityClient({
    projectId: "mt5nswih",
    dataset: "production",
    apiVersion: "2021-10-21", // use current UTC date - see "specifying API version"!
    token: "", // or leave blank for unauthenticated usage
    useCdn: true, // `false` if you want to ensure fresh data
  });

  React.useEffect(() => {}, []);

  const setInfoBounds = (bounds) => {
    if(bounds.x > 0 && window) {
      let shift = (window.innerWidth / 2 - bounds.x) * -1;
      setInfoShiftValue(shift.toFixed(2));
    }
  }

  return (
    <SiteContext.Provider
      value={{
        ...defaultValues,
        client,
        setInfoBounds,
        InfoShiftValue,
        IsHeightSet

      }}
    >
      {children}
    </SiteContext.Provider>
  );
};
