import React from 'react';
import styled from 'styled-components';
import { type } from 'utils/type';
import { fontstack } from 'utils/fontstack';
import { media } from 'utils/mediaQuery';

const Wrapper = styled.div`
  position: relative;
  ${fontstack.default}
  ${type('headmb')}
  display: flex;
  align-items: flex-start;
  white-space: nowrap;

  ${media.small`
    ${type('metadata')}
  `}
`

const Num = styled.span`
  ${fontstack.secondary}
  ${type('superscript')}
  color: rgb(var(--brand-grey));
  margin-left: 2px;
`

const MenuItem = React.forwardRef(({children, num, ...rest},ref) => {

  return (
    <Wrapper ref={ref} {...rest}>
      {children}
      { num && <Num>{num}</Num>}
    </Wrapper>
  )
})

export default MenuItem;
