import React from "react";
import styled from "styled-components";
import { useTransition, animated } from 'react-spring'
import Header from "modules/Header/Header";
import LoadingScreen from "modules/LoadingScreen/LoadingScreen";
import { SiteContext } from 'context/site-context';
import { GlobalStyle } from 'utils/global';
import useMatchMedia from 'utils/matchMedia';
import "fonts/fonts.css"

const Base = ({ location, children }) => {
  const { IsHeightSet } = React.useContext(SiteContext);
  const [ UnLoad, setUnLoad ] = React.useState(false);
  const [ UnLoading, setUnLoading ] = React.useState(false);
  const isDesktop = useMatchMedia('(min-width:768px)', true)
  const [ trigger, setTrigger ] = React.useState(null)
  const transitions = useTransition(children,  {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    exitBeforeEnter: true,
    onRest: (result) => {
      // hacky way of setting the info shift value. We add a timeout to estimate the transition between the two routes.
      if(result.value.opacity === 0 && location.pathname !== "/reference"){
        setTimeout(() => {
          setTrigger(true)
        }, 50)
        
      } 
    }
  })

  React.useEffect(() => {
    if(IsHeightSet) {
      setTimeout(() => {
        setUnLoading(true)
      }, 1000);
    }
  }, [IsHeightSet])

  return (
    <>
      <GlobalStyle />
      { !UnLoad && <LoadingScreen state={UnLoading} setUnLoad={setUnLoad}/> }
      <Header location={location} triggeredTransition={trigger}/>
      { transitions((props, item) => (
        <animated.div style={props}>
          { item }
        </animated.div>
      )) }
    </>
  );
};

export default Base;