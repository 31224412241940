import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  ::selection {
    background: var(--brand-grey1);
    color: var(--brand-white);
  }

  * {
    box-sizing: border-box;
  }

  html, 
  body {
    overscroll-behavior: none;
    background: rgb(var(--brand-background));
    height: 100%;
  }
  
  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Basis Grotesque Pro",Arial,Helvetica Neue,Helvetica,sans-serif;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
  
  :root {
    --brand-black: 17, 17, 17;
    --brand-background: 251, 248, 244;
    --brand-white: 255, 255, 255;
    --brand-grey: 153, 153, 153;
    --brand-brand: 168, 123, 81;

    --brand-primary: var(--brand-black);
    --brand-secondary: var(--brand-white);
  }
`;
