import React, { useLayoutEffect, useEffect, useRef } from "react";
import styled from 'styled-components';
import { useSpring, useChain, useSpringRef, to as interpolate, animated, config } from '@react-spring/web';
import { SiteContext } from 'context/site-context';
import { SectionGutters } from 'components/Section/Section';
import { type } from 'utils/type';
import { fontstack } from 'utils/fontstack';
import { media } from 'utils/mediaQuery';


const Animate = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgb(var(--brand-background));
`
const Wrapper = styled(SectionGutters)`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
`

const Inner = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const Line = styled(animated.div)`
  ${fontstack.default}
  ${type('headmb')}


  ${media.small`
    ${type('metadata')}
  `}
`

const Statement = styled(Line)`
  ${fontstack.secondary}
  color: rgb(var(--brand-grey));
`

const LoadingScreen = ({state, setUnLoad, ...rest}) => {
  const { InfoShiftValue } = React.useContext(SiteContext);
  const defaultBodyAttrs = useRef({
    overflow: '',
    width: '',
  })
  const prevBodyAttrs = useRef();

  const [ propsName, apiName ] = useSpring(() => ({ to: { opacity: 1}, immediate: true }))
  const [ propsLoading, apiLoading ] = useSpring(() => ({ to: { opacity: 1}, immediate: true }))
  const [ propsWrapper, apiWrapper ] = useSpring(() => ({ to: { opacity: 1}, immediate: true }))

  useLayoutEffect(() => {
    bodyScrollDisable();


    return () => {
      bodyScrollEnable();
    }

  }, [])

  useEffect(()=> {
    if(state) {
      apiName.start({
        opacity: 0,
        config: {
          duration: 200
        },
        onRest: () => {
          apiLoading.start({
            opacity: 0,
            config: {
              duration: 200
            },
            onRest: () => {
              apiWrapper.start({
                opacity: 0,
                config: {
                  duration: 200
                },
                onRest: () => {
                  setUnLoad(true)
                }
              })
            }
          })
        }
      })

      
    }
  }, [state])

  const bodyScrollDisable = () => {
    prevBodyAttrs.current = {
      overflow: document.body.style.overflow,
      width: document.body.style.width,
    }
     // Get clientWidth before setting overflow: 'hidden'
     const clientWidth = document.body.clientWidth
     document.body.style.overflow = 'hidden'
     document.body.style.width = `${clientWidth}px`
  }

  const bodyScrollEnable = () => {
    document.body.style.width = prevBodyAttrs.current.width
    document.body.style.overflow = prevBodyAttrs.current.overflow
    prevBodyAttrs.current = defaultBodyAttrs.current
  }

  return (
    <Animate style={propsWrapper} {...rest}>
      <Wrapper>
        <Inner>
          <Line style={propsName}>Abdela Igmirien</Line>
          <Statement style={propsLoading}>Loading</Statement>
        </Inner>
      </Wrapper>
    </Animate>
  );
}

export default LoadingScreen;
