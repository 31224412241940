import * as React from "react";
import { SiteProvider } from "./src/context/site-context";
import Base from "./src/components/Base/Base";
import Header from './src/modules/Header/Header';

export const wrapRootElement = ({ element }) => (
  <SiteProvider>{element}</SiteProvider>
);

export const wrapPageElement = ({ element, props }) => {
  return (
    <Base {...props}>{element}</Base>
  )
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  window.history.scrollRestoration = "manual";
  // transition duration from `layout.js` * 1000 to get time in ms
  // * 2 for exit + enter animation
  const TRANSITION_DELAY = 0.45 * 1000;
  const savedPosition = getSavedScrollPosition(location, location.key) || [0, 0];
  window.setTimeout(
    () => window.scrollTo(...savedPosition),
    TRANSITION_DELAY
  );

  return false;
};
